import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-expired',
  templateUrl: './link-expired.component.html',
  styleUrls: ['./link-expired.component.scss']
})
export class LinkExpiredComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
