import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-community-guidelines',
  templateUrl: './community-guidelines.component.html',
  styleUrls: ['./community-guidelines.component.scss']
})
export class CommunityGuidelinesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
