import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styleUrls: ['./termandcondition.component.scss']
})
export class TermandconditionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
