import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.scss']
})
export class UserguideComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
